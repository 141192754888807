import {graphql, withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import machineLearning from '../../../../src/images/portfolio/fairlingo/machine-learning-2.gif';
import {Button} from '../../../components/button';
import {CaseHeader} from '../../../components/case_header';
import {WillAnimate} from '../../../components/content_animate';
import {Layout} from '../../../components/layout';
import {ScrollTo} from '../../../components/scroll_to';

const translatedPagePath = '/en/portfolio/translation-platform-fairlingo/';

export default ({data}: any) => (
    <Layout
        title="Case: Vertaalplatform Fairlingo – Alles over de technische ontwikkeling van het Fairlingo platform – Voorbeeld van ons werk"
        description="Fairlingo is een online vertaalplatform waar klant en vertaler bij elkaar komen. Transparantie en technische diepgang maken het allemaal mogelijk."
        ogImage="fairlingo.png"
        translatedPagePath={translatedPagePath}
        navColor="white">
        <CaseHeader
            urlBack="/nl/portfolio/saas-product-keeping/"
            titleBack="Case: Ons eigen product: Keeping"
            urlForward="/nl/portfolio/taxatieplatform-taxapi/"
            titleForward="Case: Taxatieplatform TaXapi"
            translatedPagePath={translatedPagePath}>
            <Img className="hero__image" alt="RP3" fluid={data.fairlingo.childImageSharp.fluid} critical />

            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h1>
                        {/* Make sure to have the correct spacing when br becomes invisible */}
                        Uber voor <br /> vertalingen
                    </h1>
                    <h2>Vertaalplatform Fairlingo</h2>
                </div>
            </div>
            <ScrollTo className="hero__scroll" target="/nl/portfolio/vertaalplatform-fairlingo/#from-hero" />
        </CaseHeader>

        <section className="section">
            {/* Case intro */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--intro">
                        <h2>
                            Fairlingo is een van de grootste vertaal&shy;platformen in Europa. Dankzij de{' '}
                            <span className="highlight">innovatieve aanpak</span> en de strakke uitvoering van het{' '}
                            vertaal&shy;platform, loopt Fairlingo voorop in de online vertaal&shy;wereld. Samen met de
                            initiatief&shy;nemer ontwikkelden we Fairlingo vanaf de grond tot de complete
                            vertaal&shy;oplossing die het nu is.
                        </h2>
                    </div>
                </div>
            </WillAnimate>

            {/* Case assignment */}
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>Opdracht</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            De initiatief&shy;nemer van Fairlingo was al meer dan tien jaar actief in de wereld van het{' '}
                            ver&shy;talen, voornamelijk met zijn eigen vertaal&shy;bureau. Hij kwam bij ons met een{' '}
                            innovatief idee voor een online vertaal&shy;platform.
                        </p>
                        <p>
                            Wat moest het worden? Een plek waar iedereen eenvoudig en direct teksten kan laten vertalen{' '}
                            met een hoog kwaliteitsniveau, gegarandeerd gedaan door{' '}
                            <span className="highlight">echte menselijke vertalers</span>. Een transparant, modern en{' '}
                            vooral{' '}
                            <a target="_blank" href="https://www.fairlingo.com">
                                gebruiks&shy;vriendelijk vertaal&shy;platform
                            </a>
                            . We waren meteen erg enthou&shy;siast en schoven aan als strategisch en technisch partner.
                        </p>
                        <p>
                            We gingen samen de uitdaging aan om een product te ontwikkelen waar vertaler en klant{' '}
                            <span className="highlight">bij elkaar komen</span>. Een op&shy;zichzelf&shy;staand platform{' '}
                            waar kwaliteit en transparantie van een vertaal&shy;offerte en het -proces voorop staan. Een{' '}
                            doordachte, flexibele en goede toevoeging aan de applicatie-economie.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Concepting en totstandkoming productplan</li>
                            <li className="ion-md-arrow-dropright">Branding, UX- en UI-ontwerp</li>
                            <li className="ion-md-arrow-dropright">
                                Technische platformontwikkleing met Laravel en React
                            </li>
                            <li className="ion-md-arrow-dropright">
                                AI-gestuurd suggestiesysteem om menselijke vertalers te assisteren
                            </li>
                            <li className="ion-md-arrow-dropright">Bewaken van de beschikbaarheid van het platform</li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="3D weergave van de verschillende elementen in de Fairlingo vertaalinterface"
                            fluid={data.fairlingo3d.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            De interface van Fairlingo. Gemakkelijk, productief en gemaakt voor vertalers.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Case challenge */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>Uitdaging</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Elke vertaal&shy;opdracht is <span className="highlight">uniek</span>, het{' '}
                            Fairlingo-platform moest zich daarom kunnen aan&shy;passen aan de eisen van industrieën,{' '}
                            opdrachten en klanten uit ver&shy;schillende landen, met vele ver&shy;schillende talen.
                        </p>
                        <p>
                            Een grote uitdaging bij het ontwikkelen van het vertaal&shy;platform was het ontwerpen en{' '}
                            implementeren van een UX die voor iedereen direct intuïtief aanvoelde. Zowel voor klanten{' '}
                            met een vertaalopdracht, als voor vertalers die op&shy;drachten uit&shy;voeren. We wilden{' '}
                            ver&shy;talers graag assisteren met{' '}
                            <span className="highlight">slimme hulp&shy;middelen</span>, zodat zij zich volledig kunnen{' '}
                            toe&shy;leggen op de vertaal&shy;opdracht.
                        </p>
                        <p>
                            Om te verzekeren dat ver&shy;talingen van hoge kwaliteit zijn, moesten we een proces{' '}
                            op&shy;zetten waarin andere deel&shy;nemende ver&shy;talers vertaal&shy;werk beoordelen,{' '}
                            nakijken en eventueel reviseren. Dit proces moest fan&shy;tastische ver&shy;talingen extra{' '}
                            belonen, en slechte ver&shy;talingen ontmoedigen.
                        </p>
                        <p>
                            Het platform moest flexibel omgaan met de vele verschillende{' '}
                            <span className="highlight">bestands&shy;formaten</span> waarin bron&shy;teksten kunnen{' '}
                            worden aan&shy;geleverd. Daarbij moesten vertalingen, waar mogelijk, ook in hetzelfde{' '}
                            bestands&shy;formaat op&shy;geleverd worden.
                        </p>
                        <p>
                            Zowel klant als ver&shy;taler wil continu inzicht hebben in het vertaal&shy;proces, van{' '}
                            begin tot eind. Dat betekende dat een opdracht van offerte tot op&shy;levering altijd{' '}
                            <span className="highlight">live te volgen</span> moest zijn in Fairlingo.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <Img
                                        alt="Voorbeeld van lijst met het aanmaken van een order in Fairlingo"
                                        fluid={data.fairlingoOrder.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Gemakkelijk opdrachten insturen</h2>
                            <p>
                                De user interface in Fairlingo is voor de opdracht&shy;gever zo intuïtief mogelijk{' '}
                                gemaakt voor het toevoegen van nieuwe opdrachten. De belangrijkste onder&shy;delen zijn
                                direct binnen hand&shy;bereik: het uploaden van een document, het kiezen van de taal van
                                oorsprong, de doel&shy;taal en het op&shy;vragen van een offerte. Dankzij het
                                transparante proces en slimme algoritmen die om kunnen gaan met allerlei
                                bestandsformaten ontvang je <span className="highlight">binnen 30 seconden</span> al een
                                optimale offerte voor de vertaal&shy;opdracht.
                            </p>
                            <p>
                                Daarnaast volg je als opdracht&shy;gever live de vertaling, inclusief{' '}
                                tussen&shy;versies. Ook heb je zelf de mogelijkheid om het niveau van de ver&shy;taling
                                te kiezen. We hebben de functie toe&shy;gevoegd om te kunnen kiezen voor een
                                ver&shy;taler waar je al een keer mee gewerkt hebt, onmisbaar als je bepaalde
                                terminologie uit ver&shy;schillende vertaal&shy;opdrachten consequent vertaald wilt
                                hebben.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__image content__image--right">
                        <div className="image image--detail">
                            <div className="image__content">
                                <Img
                                    alt="Interface van Fairlingo met balans en het zoeken van opdrachten"
                                    fluid={data.fairlingoMoneys.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="content__copy">
                        <h2>Applicatie-economie</h2>
                        <p>
                            Als vertaler meld je je gemakkelijk aan bij Fairlingo, na een aantal verificatie&shy;stappen{' '}
                            kun je direct beginnen met het accepteren van opdrachten. Je geeft je taal&shy;voorkeuren{' '}
                            aan en hebt direct vertaal&shy;opdrachten voor het kiezen. Zo bepaal je ook zelf je{' '}
                            werk&shy;tempo en de hoeveel&shy;heid op&shy;drachten die je accepteert.
                        </p>
                        <p>
                            Alle vertalingen die je als vertaler instuurt worden nagekeken door een tweede vertaler om
                            de kwaliteit van de vertalingen te waar&shy;borgen en het proces transparant te houden voor
                            de klant. Na goed&shy;keuring van de ver&shy;talingen ontvang je{' '}
                            <span className="highlight">direct het geld op je account</span>. Zo gemakkelijk is dat.
                        </p>
                        <p>
                            Naarmate je meer vertaalt ontvang je niet alleen een mooie vergoeding vanuit Fairlingo maar
                            je ontvangt ook <span className="highlight">ervarings&shy;punten</span>. Na het vergaren van
                            flink wat punten kan je toegang krijgen tot specialistische vertaal&shy;opdrachten die
                            gepaard gaan met een hogere vergoeding.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image image--detail">
                                <div className="image__content">
                                    <img alt="Fairlingo machine learning vertaalmodel in actie" src={machineLearning} />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Slimme suggesties</h2>
                            <p>
                                Voor Fairlingo hebben we in een paar weken tijd een proto&shy;type van een{' '}
                                <span className="highlight">suggestie&shy;systeem</span> ontwikkeld ter ondersteuning
                                van de menselijke vertalers. We deden dit door een neuraal vertaalmodel te trainen met
                                behulp van TensorFlow en de data die wij tot onze beschikking hadden.
                            </p>
                            <p>
                                Het systeem werkte snel en gaf goede suggesties tijdens het vertalen van een opdracht.{' '}
                                In tegenstelling tot vertaal&shy;sites neemt ons suggesties&shy;ysteem een deel van de
                                reeds vertaalde tekst mee in de suggestie. De auto-aanvullen-functie past de suggesties
                                automatisch tijdens het vertalen aan. Een ideaal hulpmiddel voor de vertaler. In plaats
                                van een concurrent zijn, helpt AI nu de menselijke vertaler. Ze ver&shy;sterken elkaar
                                maken het vertaal&shy;proces nog soepeler en beter.{' '}
                            </p>
                            <p>
                                Het verwerken van <span className="highlight">machine learning</span> in een product kan{' '}
                                verschillende voor&shy;delen met zich mee&shy;brengen, zoals een uniek
                                onder&shy;scheidend kenmerk t.o.v. andere diensten. Voor meer infor&shy;matie over het
                                machine learning-model dat we ont&shy;wikkelden, en de resultaten daarvan, hebben we een{' '}
                                <a
                                    target="_blank"
                                    title="Paper over interactive neural translation (PDF)"
                                    href={withPrefix('/files/scheepers-schulz-interactive-neural-translation.pdf')}>
                                    weten&shy;schappelijke publicatie
                                </a>{' '}
                                (Engels, PDF) geschreven.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Verschillende schermen van vertaalplatform Fairlingo"
                            fluid={data.fairlingoScreens.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Fairlingo biedt zowel klant als vertaler de mogelijkheid om continu het proces te volgen.{' '}
                            Deze tekst die je nu leest is ook vertaald met Fairlingo. We wilden graag over deze case{' '}
                            vertellen op zowel onze Nederlandse als op onze Engelse website&mdash;ideale toepassing van
                            Fairlingo nietwaar?
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Iteratief <br /> software <br /> ontwikkelen
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Een intensieve samen&shy;werking met de <span className="highlight">product owner</span> bij{' '}
                            Fairlingo zorgt ervoor dat we altijd op de hoogte zijn van de toekomstige{' '}
                            ontwikkel&shy;plannen.
                        </p>
                        <p>
                            Op de hoogte zijn van de lange&shy;termijn&shy;plannen van een product geeft ons de{' '}
                            mogelijk&shy;heid om iteratief te werken aan nieuwe functies. Via een agile{' '}
                            ontwikkel&shy;proces doen we tussen&shy;opleveringen die getest worden door het{' '}
                            product&shy;team bij Fairlingo.
                        </p>
                        <p>
                            Het <span className="highlight">testen van nieuwe functies</span> met echte vertalers komt{' '}
                            het product altijd ten goede. Zo krijg je van gebruikers inzichten die je anders niet had{' '}
                            kunnen ver&shy;werken in een nieuwe product&shy;versie.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Tijdlijn van het verloop van hoe de vertaalinterface is geevolueerd"
                            fluid={data.fairlingoTimeline.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Langdurige samenwerking in beeld. De interface van Fairlingo heeft verschillende gezichten
                            gekend: van een interface met invloeden uit het skeuomorfisme, tot een lichte, neutrale
                            interface.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Ook een <br /> platform <br /> creëren?
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Met Fairlingo hebben we uniek platform gecreëerd dat vertalers en klanten met
                            vertaal&shy;opdrachten bij elkaar brengt. Heb jij ook een interessant plan voor een{' '}
                            <span className="highlight">applicatie-economie&shy;platform</span>? Neem contact op met{' '}
                            <span className="inline-avatar">
                                <Img alt="Olav" fixed={data.olav.childImageSharp.fixed} Tag="span" />
                                <span>Olav</span>
                            </span>{' '}
                            voor een verkennend gesprek.
                        </p>
                    </div>
                    <div className="content__buttons content__buttons--50">
                        <Button url="/nl/contact/" name="Neem contact met ons op" title="Contactgegevens Enschede" />
                    </div>
                </div>
            </WillAnimate>
        </section>
    </Layout>
);

export const query = graphql`
    query {
        fairlingo: file(relativePath: {eq: "portfolio/fairlingo/fairlingo.jpg"}) {
            ...fullscreenHero
        }

        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        fairlingoScreens: file(relativePath: {eq: "portfolio/fairlingo/screens.png"}) {
            ...fullscreenHero
        }
        fairlingoOrder: file(relativePath: {eq: "portfolio/fairlingo/order.png"}) {
            ...fluid1200Width
        }
        fairlingoMoneys: file(relativePath: {eq: "portfolio/fairlingo/moneys.png"}) {
            ...fluid1200Width
        }
        fairlingo3d: file(relativePath: {eq: "portfolio/fairlingo/3d.png"}) {
            ...fullscreenHero
        }
        fairlingoTimeline: file(relativePath: {eq: "portfolio/fairlingo/timeline.png"}) {
            ...fullscreenHero
        }
    }
`;
